import React, { useState, useEffect, useContext } from "react";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import { Grid, Typography, Paper } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import successIcon from '../assets/payment-success-icon.png';
import PaymentComp from '../components/PaymentComp'
import ChildCareComp from '../components/ChildCareComp'
import logo from '../assets/images/cards/Chinmaya-Mission-Logo.gif';
import { DataContext } from '../App';

export default function EndEventForm() {
    const [eventData, setEventData] = useState({
        parent: {
            memberType: "parent",
            firstName: "",
            lastName: "",
            mobileNumber: "",
            email: "",
            gender: "",
            age: 5,
            grade: "",
            tshirtSize: "",
            streetAddress: "",
            city: "",
            state: "",
            postalCode: "",
            allergies: "",
            notes: "",
            childCareReqd: false,
            volunteer: ""
        },
        spouse: {
            memberType: "spouse",
            firstName: "",
            lastName: "",
            mobileNumber: "",
            email: "",
            gender: "",
            age: 0,
            grade: "",
            streetAddress: "",
            city: "",
            state: "",
            postalCode: "",
            allergies: "",
            notes: "",
            childCareReqd: "",
            volunteer: ""
        },
        children: [{
            memberType: "child",
            firstName: "",
            lastName: "",
            mobileNumber: "",
            email: "",
            gender: "",
            age: 0,
            grade: "",
            tshirtSize: "",
            streetAddress: "",
            city: "",
            state: "",
            postalCode: "",
            allergies: "",
            foodAllergies: "",
            medicalAllergies: "",
            environmentalAllergies: "",
            prescriptionMedications: "",
            otcMedications: "",
            notes: "",
            childCareReqd: "",
            volunteer: ""
        }],
        guest: [],
        transaction: {
            quantities: 1,
            itemPrice: 0,
            totalAmount: "",
            donationAmount: "",
            paymentStatus: "",
            payerId: ""
        },
        medical: {
            pcpName: "",
            pcpMobile: "",
            medicalInsurance: "",
            groupNo: "",
            idNo: "",
            medicalNotes: "",
            medicalConsentSignedBy: "",
            treatmentConsentSignedBy: ""
        },
        tshirts: [],
        eventType: 'Banquet',
        eventTypeId: 15,
        center: 'other',
        notes: '',
        sessionName: "",
        otherCenter: "",
        feeEnabled: 0,
        success: false
    });
    //const ticketPrice = process.env.REACT_APP_NAADABINDU_PRICE;
    const eventTypes = useContext(DataContext);
    const [ticketPrice, setTicketPrice] = useState(0); 



    console.log("Event data in Payment form :", JSON.stringify(eventData), eventData);
    const [inputFields, setInputFields] = useState(eventData.transaction);
    const [parentFields, setParentFields] = useState(eventData.parent);

    const [feeEnabled, setFeeEnabled] = useState(0);
    const [donation, setDonation] = useState(0);
    const [totalAmount, setTotalAmount] = useState(inputFields.quantities * inputFields.itemPrice);

    const [otherDonationFlag, setOtherDonationFlag] = useState(false);

    const [noOfPeople, setNoOfPeople] = useState(inputFields.quantities);

    const [selectedAmount, setSelectedAmount] = useState(0);

    useEffect(() => {
        eventTypes.events.map((events, index) => (
             events.name === 'Banquet' ? 
                setTicketPrice(events.price)                
             : ''               
                ));       
   
    }, [])         // Empty dependency array ensures this runs once on mount
    
    const total = () => {
        inputFields.donationAmount = donation;
        inputFields.totalAmount = totalAmount;
        eventData.transaction = inputFields;
    }
    total();
    //console.log("Donation, totalamounts at the end:", (eventData), inputFields, donation, totalAmount);
    
    useEffect(() => {
        eventData.transaction.quantities = noOfPeople;
        eventData.transaction.itemPrice = ticketPrice;
        if (feeEnabled) {
            setTotalAmount(((parseInt(noOfPeople) * parseInt(ticketPrice) + parseInt(donation)) * 1.03).toFixed(2));
        } else {
            setTotalAmount((parseInt(noOfPeople) * parseInt(ticketPrice) + parseInt(donation)) * 1.00);
        }

        eventData.transaction.totalAmount = totalAmount;
    }, [noOfPeople, donation, feeEnabled, ticketPrice])
  
   
    const checkoutHandler = (e) => {
        e.preventDefault();
        //    console.log("transaction before sent to SQS:", (inputFields));
        if (sendToServer()) {
            setCheckout(true);
        } else {
            setCheckout(false);
        }
    };

    function handleParentChange(e) {
        let newForm = { ...parentFields };
        newForm[e.target.name] = e.target.value;
        setParentFields(newForm);
        eventData.parent = newForm;
    }
    
    function handleChange(e) {
        //Handle any changes to the text fields
        setEventData({ ...eventData, [e.target.name]: e.target.value });
    }

    function sendToServer() {
        console.log("transaction before sent to SQS:", (eventData));

        try {
            const url = process.env.REACT_APP_SQS_URL;

            const response = fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(eventData),
            });
            console.log('API response:', response.data);
        } catch (error) {
            console.error('Error:', error);
            return false;
        }
        return true;
    }
    function handlePeople(e) {
        setNoOfPeople(parseInt(e.target.value));
    }

    function feeSwitchHandler(e) {
        setFeeEnabled(e.target.checked);
        eventData.feeEnabled = e.target.checked ;
    }

    function handleBack() {
        setCheckout(false);
    }


    function handleDonation(e) {
        setInputFields({ ...inputFields, [e.target.name]: parseInt(e.target.value) });
        if (e.target.value >= 0) {
            setDonation(parseInt(e.target.value));
        }
    };

    const [success, setSuccess] = useState(false)
    const [checkout, setCheckout] = useState(false)

    return (
        <div>
            
                <Grid item xs={12} sm={12}>
                    <Typography variant="h6" align="center" gutterBottom>
                        {'CMDFW Event Registration System'}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <img src={logo} className="App-logo" alt="logo" />
                </Grid> 
                <Grid item>
                                    <Typography variant="body2" align="center" gutterBottom>
                                        <div>Thank You for your interest in the Banquet. The event is currently SOLD OUT.</div>
                                        <div>Please email us at <a href="mailto:events@cmdfw.org">events@cmdfw.org</a> to be added to the waitlist. </div>

                                    </Typography>
                                    
                                </Grid> 
            
        </div >
    );
}